import ScrollTrigger from '@terwanerik/scrolltrigger';
import easing from 'jquery.easing';
import Swiper from 'swiper';
import luxy from 'luxy.js';
import selectric from 'selectric';
import 'selectric/public/selectric.css';
import simpleParallax from 'simple-parallax-js';
// import {gsap} from "gsap";
// import {ScrollTrigger} from 'gsap/ScrollTrigger';

//gsap.registerPlugin(ScrollTrigger);

export class App {
  constructor(){
    this.body = document.body;
    this.page = document.getElementById('page');
    this.header=document.getElementById('masthead');
    this.hmContainer = document.getElementById('hmContainer');
    this.navContainer = document.getElementById('navContainer');
    this.menu = document.getElementById('menu-1');
    this.btnHm=document.getElementById('btnHm');
    this.btnMenuBack = document.getElementById('menuBack');
    this.speed = 900;
    this.ease = "easeInOutExpo";
  }
  init(){
    console.log('init');

    //document.querySelector('.site-title_image .st0').style.fill="#abc";
    //window.scrollTo(0,0);
    //$('html,body').animate({scrollTop:0},'1');
    window.addEventListener('load',(e)=>{

      this.body.classList.add('loaded');
      this.page.classList.add('on');
      const _this = this;
      $('.spinner').fadeOut(function(){
        $(this).remove();
        setTimeout(()=>{
          _this.hmContainer.classList.add('view');
        },300);
      });

      setTimeout(()=>{
        this.header.classList.add('on');
        //this.hmContainer.classList.add('view');
      },500);

      if(ua != 'mobile'){
        let pt = {
          wrapper:'#page',
          targets: '.lx',
          wrapperSpeed: 0.08
        }
        if(this.body.classList.contains('home')){
          //pt.targets='.site-hero_container';
        }
        luxy.init(pt);
      }else{
        const elm = document.querySelector('.hero_image');
      const parallaxConfig = {
      delay: 0,
      orientation: "down",
      scale: 1.3,
      overflow: false,
      //customContainer:'.sec2',
      customWrapper:".img_wrap"
      //maxTransition : 50
    };

   const parallax_instance = new simpleParallax(elm, parallaxConfig);
      }

      if(this.body.classList.contains('home')){
        // if(window.location.hash){
        //   const hash = window.location.hash;
        //   const cl = hash.replace("#",".sec_");
        //   const target = document.querySelector(cl);

        //   this.anim(target,"link");
        // }
      }
    });

    if(this.body.classList.contains('home')){
      if(document.querySelector('.sec_media .image_logo') != null) {
      const image_logo_cl = document.querySelector('.sec_media .image_logo').cloneNode(true);
      image_logo_cl.classList.add('cp');
      const txt_container = document.querySelector('.sec_media .txt_container');
      //console.log(txt_container);
      txt_container.insertBefore(image_logo_cl, txt_container.firstElementChild);
      }

      const image_logo_cl_im = document.querySelector('.sec_products .image_logo').cloneNode(true);
      image_logo_cl_im.classList.add('cl');
      const image_pro = document.querySelector('.sec_products .image');
      image_pro.appendChild(image_logo_cl_im);

      const sns = document.querySelectorAll('.sec-container .sns_container');
      if(sns){
        for(const _sn of sns){
          const _pa = $(_sn).parents('.sec-container').find('.btn_container')[0];
          const _cl = _sn.cloneNode(true);
          _cl.classList.remove('sp');
          _cl.classList.add('pc');
          _pa.appendChild(_cl);
        }
      }

    }
    this.events();
    this.trigger();
  }
  anim(_tar,_type,_add=0){
    const _this = this;
    let _scTop = 0;
    console.log(_add);
    //setTimeout(()=>{
      const _rect = _tar.getBoundingClientRect();
      const _top = _rect.top - _add;
      if(_type === 'home'){
        _scTop = window.pageYOffset || document.documentElement.scrollTop;
      }
      console.log(_type);
      $('html,body').animate({
        scrollTop:_top + _scTop
      },_this.speed, _this.ease);
      _this.menuclose();
    //},100);
  }
  events(){
    this.btnHm.addEventListener('click',(e)=>{
      if(!this.navContainer.classList.contains('on')){
        this.navContainer.classList.add('on');
      }else{
        this.menuclose();
      }
    });

    this.btnMenuBack.addEventListener('click',()=>{
      this.menuclose();
    });

     this.sels = document.querySelectorAll('select');
      this.sel_arr = [];
      for(const sel of this.sels){
        this.sel_arr.push($(sel).selectric());
      }

    if(this.body.classList.contains('home')){
      const menu_custom = this.menu.querySelectorAll('.custom a');
      for(const _item of menu_custom){
        _item.addEventListener('click',(e)=>{
          const _href = e.currentTarget.getAttribute('href');
          //const _hash = _href.replace(home_url+"/#",'.sec_');
          const _hash = _href.replace(home_url+"/#",'');
          //const _target = document.querySelector(_hash);
          const _target = document.getElementById(_hash);
          const _add_num = (ua === "others") ? 140 : 70;
          const _add = 0;//(_hash === ".sec_products" || _hash === ".sec_media") ? _add_num : 0;
          console.log(_hash);

          if(_target){
            this.anim(_target,'home',_add);
          }
        });
      }
      const sec_arr = ['.sec_products','.sec_media'];
      //for(const _sec of sec_arr){
        // const _pa = document.querySelector(_sec);
        // const _elm = _pa.querySelector('.sec-ttl img');
        // gsap.to(_elm, {
        //   translateY:0,
        //   opacity:1,
        //   duration:1,
        //   scrollTrigger:{
        //     trigger: _pa,
        //     start: "top bottom",
        //     end: "top center",
        //     scrub: true,
        //     //markers: true
        //   }
        // });

        // const elm_tx = _pa.querySelectorAll('.gj-x');
        // for(const _elm of elm_tx){
        //   gsap.fromTo(_elm, {
        //     translateX:200,
        //     opacity:0
        //   },{
        //     alpha:1,
        //     translateX:0,
        //     scrollTrigger:{
        //       trigger: _pa,
        //       start: "top 70%",
        //       end: "top 30%",
        //       scrub: 1,
        //       markers: true
        //     }
        //   });
        // }
      //}

      // gsap.to('.site-hero_container',{
      //   y:180,
      //   //duration:1,
      //   scrollTrigger: {
      //     trigger: '.site-header',
      //     start: 'top top',
      //     end: 'bottom top',
      //     scrub: true,
      //     markers: true
      //   }
      // });
    }
    if(this.body.classList.contains('page') && this.body.classList.contains('news')){
      const btnClear = document.getElementById('btnClear');
      const ops = document.querySelectorAll('.filterList select option');

      //console.log(sel_arr);
      btnClear.addEventListener('click',()=>{
        for(const op of ops){
          // sel.selectedIndex = -1;
          $(op).prop('selected',false);
          $(op).attr('selected',false);
          //console.log(sel);
        }
        for(const _sel of this.sel_arr){
          //console.log(_sel);
          _sel.selectric('refresh');
        }
      });
    }
  }
  trigger(){
    this.trigger = new ScrollTrigger({
      trigger: {
        offset: {
          viewport: {
            x: 0,
            y: (trigger, rect, direction) => {
              return 0.2;
            }
          }
        },
        toggle: {
          callback: {
            in:(trigger)=>{
            },
            out:(trigger)=>{
            }
          }
        }
      }
    });
    this.trigger.add('[data-scroll]', {
      once: true,
      toggle: {
        class: {
          in: 'animIn',
          out: 'animOut'
        },
        callback: {
          in:(trigger) => {

          },
          out:(trigger) => {

          }
        }
      }
    })
  }
  menuclose(){
    this.navContainer.classList.remove('on');
    this.btnHm.querySelector('.bar:nth-child(1)').style.animationName="bar1";
    this.btnHm.querySelector('.bar:nth-child(3)').style.animationName="bar3";
  }
}
