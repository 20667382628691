import 'reset-css/reset.css';
import '../scss/site.scss';
//import 'swiper/swiper-bundle.css';
//import '../scss/site.scss';

import {App} from './Lib';

window.app = new App();

app.init();
